import React from "react";
import Footer from "../components/Footer";
import warehouse_1 from "./Gallery/warehouse5.jpg"
import warehouse_2 from "./Gallery/warehouse17.jpg"
import warehouse_3 from "./Gallery/warehouse8.jpg"
import warehouse_4 from "./Gallery/warehouse12.jpg"
import warehouse_5 from "./Gallery/warehouse14.jpg"

function Gallery() {
  

  return (
    <div className="w-full relative overflow-x-hidden">
      <div className="w-full pt-[10px] pb-[150px] relative z-0 top-[5rem] flex flex-col gap-[10px] justify-center items-center overflow-x-hidden">
        <div className="flex gap-3 flex-col justify-center items-center">
          <h1 className="text-[35px] font-bold font-serif">Gallery</h1>
          <p className="w-[60%] text-center text-gray-500 text-[15px]">
          Welcome to the Bhandari Repository Gallery, where we showcase the heart of our operations. </p>
        </div>

        <div className="w-[70%] h-[600px] p-5 pb-6 overflow-y-auto hide-scrollbar ">
          <div className="w-[100%] h-[100%] flex flex-wrap justify-around items-center gap-9">
            
            <div className="w-[300px] h-[300px]">
                <img
                  src={warehouse_1}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="w-[300px] h-[300px]">
                <img
                  src={warehouse_2}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="w-[300px] h-[300px]">
                <img
                  src={warehouse_3}
                  className="w-full h-full object-cover"
                />
              </div>

              <div className="w-[300px] h-[300px]">
                <img
                  src={warehouse_4}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-[300px] h-[300px]">
                <img
                  src={warehouse_5}
                  className="w-full h-full object-cover"
                />
              </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Footer/>
      </div>
    </div>
  );
}

export default Gallery;
