import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar'
import Home from "./pages/Home";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Servicespage from "./pages/Servicespage";
import Gallery from "./pages/Gallery";
import ScrollToTop from './ScrollToTop'

function App() {
  return (
    <div className="App">
      <ScrollToTop/>
      <div className='w-full fixed top-0 left-0 z-10'>
        <Navbar />
      </div>
      <div className='w-full relative z-0'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/service" element={<Servicespage />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      </div>
    </div>
  );
}

export default App;
